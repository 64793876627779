.pick-list-row {
  display: grid;
  grid-template-columns: 80px 28px 300px 40px 40px 50px;
  grid-gap: 10px; }

.pick-list-row {
  margin-bottom: 10px; }
  .pick-list-row .totalRequiredQuantity,
  .pick-list-row .totalKittedQuantity {
    text-align: right; }
  .pick-list-row .toKitQuantity {
    text-align: right;
    width: 100%; }
