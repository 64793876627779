@import "./Colors.scss";

.about-container {
  display: grid;
  grid-gap: 16px;
  justify-content: center;
}

code {
  // height: max-content;
}
