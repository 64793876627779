.part-creation {
  width: 300px;
  margin: 0 auto;
  display: grid;
  grid-gap: 8px; }
  .part-creation input,
  .part-creation button,
  .part-creation select {
    padding: 4px; }
  .part-creation .part-identifier {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto; }
    .part-creation .part-identifier .revision {
      max-width: 70px;
      justify-self: right; }
  .part-creation .cost {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto; }
