.phantom-part {
  display: grid;
  grid-template-columns: 2fr 1fr 4fr 1fr;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .phantom-part .req-qty {
    justify-self: right; }
