#picklist #pick-list-columns {
  display: grid;
  grid-template-columns: 80px 28px 300px 40px 40px 50px;
  grid-gap: 10px; }

#picklist {
  width: fit-content;
  margin: 0 auto; }
  #picklist a:hover {
    text-decoration: underline; }
  #picklist #pick-list-parts > div:nth-child(odd) {
    background-color: #f5f5f5; }
  #picklist #pick-list-title {
    margin-bottom: 10px;
    display: inline-block; }
  #picklist #save-pick-list {
    float: right; }
  #picklist .numeric {
    text-align: right; }
