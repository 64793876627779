.customs-codes-badge {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #83ca8c;
  text-align: center;
  cursor: default; }
  .customs-codes-badge.missing-codes {
    background-color: #f55c5c; }
  .customs-codes-badge.missing-codes:hover {
    background-color: #ffaf67; }
  .customs-codes-badge.missing-partial-codes {
    background-color: #ffc18b; }
  .customs-codes-badge.missing-partial-codes:hover {
    background-color: #ffaf67; }
