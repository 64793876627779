@import url(https://fonts.googleapis.com/css?family=Roboto);
/* Base components */
.form-submission-btn {
  width: 130px;
  height: 40px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 2px; }
  .form-submission-btn.edit-btn {
    background-color: #ffef86; }
  .form-submission-btn.edit-btn:hover {
    background-color: #ffee7c; }
  .form-submission-btn.go-btn {
    background-color: #83ca8c; }
  .form-submission-btn.go-btn:hover {
    background-color: #7cc785; }
  .form-submission-btn.save-btn {
    background-color: #83ca8c; }
  .form-submission-btn.save-btn:hover {
    background-color: #7cc785; }
  .form-submission-btn.delete-btn {
    background-color: #FF4136; }
  .form-submission-btn.delete-btn:hover {
    background-color: #ff372c; }
  .form-submission-btn.create-btn {
    background-color: #a0e3fd; }
  .form-submission-btn.create-btn:hover {
    background-color: #96e0fd; }
  .form-submission-btn.disabled {
    cursor: not-allowed; }

.tab-btn {
  width: 100px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  background-color: #e6e6e6;
  cursor: pointer;
  position: relative; }
  .tab-btn.all {
    background-color: #e6e6e6;
    border-radius: 2px 0px 0px 0px; }
  .tab-btn.all:hover {
    background-color: #e1e1e1; }
  .tab-btn.partial {
    background-color: #a0e3fd; }
  .tab-btn.partial:hover {
    background-color: #96e0fd; }
  .tab-btn.complete {
    background-color: #83ca8c; }
  .tab-btn.complete:hover {
    background-color: #7cc785; }
  .tab-btn.pending {
    background-color: #ffb471;
    border-radius: 0px 2px 0px 0px; }
  .tab-btn.pending:hover {
    background-color: #ffaf67; }
  .tab-btn.started {
    background-color: #6ea8db; }
  .tab-btn .triangle {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin: 0 auto; }
    .tab-btn .triangle.all {
      border-top: 10px solid #e6e6e6; }
    .tab-btn .triangle.started {
      border-top: 10px solid #6ea8db; }
    .tab-btn .triangle.partial {
      border-top: 10px solid #719c89; }
    .tab-btn .triangle.pending {
      border-top: 10px solid #ffb471; }
    .tab-btn .triangle.complete {
      border-top: 10px solid #83ca8c; }

.jo-tag {
  display: inline-block;
  background-color: #f8f8f8;
  padding: 5px;
  width: 130px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .jo-tag .part-number, .jo-tag .remove {
    display: inline-block; }
  .jo-tag .remove {
    background-color: #e6e6e6;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    margin: 0 auto;
    float: right;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.dropdown-menu {
  display: inline-block; }

a {
  text-decoration: none;
  color: black; }

.partlist-row {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #e6e6e6; }
  .partlist-row .partlist-number {
    padding-left: 10px; }
  .partlist-row .job-orders {
    text-align: center;
    overflow: hidden;
    text-align: left; }
  .partlist-row .shipment-number {
    text-align: right;
    padding-right: 15px; }
  .partlist-row.default {
    background-color: #e6e6e6; }
  .partlist-row:hover {
    background-color: #e1e1e1; }

a {
  text-decoration: none;
  color: black; }

.shipment-row {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer; }
  .shipment-row .shipment-number, .shipment-row .job-orders, .shipment-row .date {
    display: inline-block; }
  .shipment-row .shipment-number {
    padding-left: 10px; }
  .shipment-row .job-orders {
    text-align: center;
    overflow: hidden;
    text-align: left; }
  .shipment-row .date {
    text-align: right;
    padding-right: 10px; }
  .shipment-row.default {
    background-color: #f8f8f8; }
  .shipment-row.default:hover {
    background-color: #f3f3f3; }
  .shipment-row.started {
    background-color: #6ea8db; }
  .shipment-row.started:hover {
    background-color: #66a3d9; }
  .shipment-row.partial {
    background-color: #a0e3fd; }
  .shipment-row.partial:hover {
    background-color: #96e0fd; }
  .shipment-row.complete {
    background-color: #83ca8c; }
  .shipment-row.complete:hover {
    background-color: #7cc785; }
  .shipment-row.pending {
    background-color: #ffb471; }
  .shipment-row.pending:hover {
    background-color: #ffaf67; }

.shipment-part-row {
  padding: 5px;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .shipment-part-row .part-number, .shipment-part-row .revision, .shipment-part-row .description {
    display: inline-block;
    margin: 0px 20px 0px 20px; }
  .shipment-part-row .part-number {
    margin-left: 20px; }

.kitted-part-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .kitted-part-row .part-number,
  .kitted-part-row .revision,
  .kitted-part-row .description,
  .kitted-part-row .kitted-qty,
  .kitted-part-row .pack-qty,
  .kitted-part-row .remaining-qty {
    display: inline-block;
    margin: 0px 20px 0px 20px; }
  .kitted-part-row .info {
    display: grid;
    grid-template-columns: 2fr 1fr 4fr; }
  .kitted-part-row .info > * {
    cursor: default; }
  .kitted-part-row .part-number {
    margin-right: 0px; }
  .kitted-part-row .description {
    font-size: 11px; }
  .kitted-part-row .part-number, .kitted-part-row .revision, .kitted-part-row .description {
    height: 21px;
    line-height: 21px; }
  .kitted-part-row .part-number:hover,
  .kitted-part-row .revision:hover,
  .kitted-part-row .description:hover {
    text-decoration: underline;
    cursor: pointer; }
  .kitted-part-row .kitted-qty, .kitted-part-row .remaining-qty {
    cursor: default; }
  .kitted-part-row .update-qty {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center; }
    .kitted-part-row .update-qty .pack-qty {
      width: 40px;
      justify-self: center; }
    .kitted-part-row .update-qty .qty-per-bag {
      width: 40px;
      justify-self: center; }
  .kitted-part-row .kitted-qty,
  .kitted-part-row .pack-qty,
  .kitted-part-row .remaining-qty {
    justify-self: left; }
  .kitted-part-row .remaining-qty {
    justify-self: center; }
  .kitted-part-row .pack-qty {
    justify-self: right; }
  .kitted-part-row .weight-each {
    width: 60px; }
  @media (max-width: 740px) {
    .kitted-part-row .part-number, .kitted-part-row .description {
      font-size: 10px; } }

.kitted-part-row:hover {
  background-color: #a0e3fd; }

.packed-part-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .packed-part-row .info > * {
    display: inline-block;
    margin: 0px 10px 0px 10px;
    cursor: default; }
  .packed-part-row .info {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 4fr; }
  .packed-part-row .item-number {
    margin: 0px; }
  .packed-part-row .part-number {
    margin-right: 0px; }
  .packed-part-row .item-number,
  .packed-part-row .part-number,
  .packed-part-row .revision,
  .packed-part-row .description {
    height: 21px;
    line-height: 21px; }
  .packed-part-row .item-number:hover,
  .packed-part-row .part-number:hover,
  .packed-part-row .revision:hover,
  .packed-part-row .description:hover {
    text-decoration: underline;
    cursor: pointer; }
  .packed-part-row .update-qty {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center; }
    .packed-part-row .update-qty .qty {
      justify-self: right; }
    .packed-part-row .update-qty .unpack-qty {
      justify-self: left;
      margin-left: 5px; }
  .packed-part-row .description {
    font-size: 11px; }
  .packed-part-row .unpack-qty {
    width: 40px; }
  .packed-part-row .pallet, .packed-part-row .box, .packed-part-row .qty {
    cursor: default;
    justify-self: left; }
  .packed-part-row .box {
    margin-left: 10px; }
  .packed-part-row .qty {
    margin-left: 20px; }
  @media (max-width: 740px) {
    .packed-part-row .part-number, .packed-part-row .description {
      font-size: 10px; } }

.packed-part-row:hover {
  background-color: #a0e3fd; }

.queued-part-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .queued-part-row .part-number, .queued-part-row .revision, .queued-part-row .description,
  .queued-part-row .required-qty, .queued-part-row .kitted-qty, .queued-part-row .weight-each {
    display: inline-block;
    margin: 0px 20px 0px 20px; }
  .queued-part-row .info {
    display: grid;
    grid-template-columns: 2fr 1fr 4fr 1fr; }
  .queued-part-row .info > * {
    cursor: default; }
  .queued-part-row .description {
    font-size: 11px; }
  .queued-part-row .part-number {
    margin-right: 0px; }
  .queued-part-row .part-number, .queued-part-row .revision, .queued-part-row .description {
    height: 21px;
    line-height: 21px; }
  .queued-part-row .part-number:hover,
  .queued-part-row .revision:hover,
  .queued-part-row .description:hover {
    text-decoration: underline;
    cursor: pointer; }
  .queued-part-row .update-qty {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center; }
  .queued-part-row .kitted-qty, .queued-part-row .weight-each {
    width: 40px; }
  .queued-part-row .required-qty {
    cursor: default;
    justify-self: right; }
  .queued-part-row .remaining-qty, .queued-part-row .kitted-qty {
    justify-self: right; }
  .queued-part-row .location {
    margin-right: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 2px;
    -webkit-align-content: space-around;
            align-content: space-around;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-width: 30px; }
  @media (max-width: 740px) {
    .queued-part-row .part-number, .queued-part-row .description {
      font-size: 10px; } }

.queued-part-row:hover {
  background-color: #a0e3fd; }

.required-part-row {
  font-weight: bold;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 6fr 2fr 1fr 1fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .required-part-row:hover {
    background-color: #a0e3fd; }
  .required-part-row .badges .badge {
    display: inline-block; }
  .required-part-row .customs-codes-badge {
    float: right;
    margin-right: 5px; }
  .required-part-row .required-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px; }
    .required-part-row .required-field .remaining {
      justify-self: right;
      margin-right: 10px; }
    .required-part-row .required-field .update-qty-input {
      justify-self: right;
      max-width: 50px; }
    .required-part-row .required-field .update-weight-input {
      justify-self: right;
      max-width: 50px; }
  .required-part-row .part-number,
  .required-part-row .revision {
    margin-left: 12px; }
  .required-part-row .units-of-measure {
    justify-self: center; }
  .required-part-row .delete-btn {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    justify-self: right; }

.add-part-row {
  padding: 5px;
  display: grid;
  grid-template-columns: 5fr 1fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .add-part-row .info {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr; }
  .add-part-row .part-number {
    width: 76px;
    margin-left: 20px; }
  .add-part-row .update-qty {
    justify-self: right;
    width: 40px;
    margin: 0; }
  .add-part-row .dropdown-menu {
    margin: 0px 20px 0px 20px; }

.translation-row {
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 200px) 1fr;
  grid-template-columns: minmax(min-content, 200px) 1fr;
  background-color: #e6e6e6;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .translation-row .language-container,
  .translation-row .description-container {
    display: inline-block; }
    .translation-row .language-container label,
    .translation-row .description-container label {
      display: block;
      margin-top: 10px; }
    .translation-row .language-container .description, .translation-row .language-container .materials,
    .translation-row .description-container .description,
    .translation-row .description-container .materials {
      width: 100%; }
    .translation-row .language-container .extended-description, .translation-row .language-container .used-for,
    .translation-row .description-container .extended-description,
    .translation-row .description-container .used-for {
      width: 100%;
      min-height: 60px;
      margin-top: 10px; }
    .translation-row .language-container .delete-btn,
    .translation-row .description-container .delete-btn {
      float: right;
      clear: right;
      margin-top: 10px; }
  .translation-row .dropdown-menu .language-container .translation {
    width: 80%; }
    .translation-row .dropdown-menu .language-container .translation select {
      width: 100%; }

.codes .code-title {
  font-size: 28px;
  margin-bottom: 10px; }

.codes label {
  color: #243241; }

.codes input {
  display: block;
  width: 100%;
  margin-bottom: 10px; }

.codes input[class*="invalid"] {
  background-color: #FF4136;
  color: white; }

.status {
  height: 68px;
  position: fixed;
  z-index: 1000;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  pointer-events: none; }
  .status .loading-icon {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    line-height: 30px; }
    .status .loading-icon .loading-square {
      position: relative;
      width: 60px;
      height: 60px;
      overflow: hidden;
      display: inline-block !important;
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-animation: bigSqrShrink 1s linear infinite;
              animation: bigSqrShrink 1s linear infinite; }
      .status .loading-icon .loading-square .square {
        position: absolute;
        width: 30px;
        height: 30px;
        background: #243241; }
      .status .loading-icon .loading-square .first {
        left: 0px;
        border-radius: 0px 0px 0px 4px;
        top: 30px; }
      .status .loading-icon .loading-square .second {
        left: 30px;
        top: 30px;
        border-radius: 0px 0px 4px 0px;
        -webkit-animation: drop2 1s linear infinite;
                animation: drop2 1s linear infinite; }
      .status .loading-icon .loading-square .third {
        left: 0px;
        top: 0px;
        border-radius: 4px 0px 0px 0px;
        -webkit-animation: drop3 1s linear infinite;
                animation: drop3 1s linear infinite; }
      .status .loading-icon .loading-square .fourth {
        left: 30px;
        top: 0px;
        border-radius: 0px 4px 0px 0px;
        -webkit-animation: drop4 1s linear infinite;
                animation: drop4 1s linear infinite; }
    .status .loading-icon .status-text {
      line-height: 30px;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 30px;
      color: #243241;
      display: block;
      margin: 15px auto;
      padding: 3px; }
  .status .success {
    color: #719c89;
    -webkit-animation: fadeOut 2s forwards;
            animation: fadeOut 2s forwards;
    background-color: #e6e6e6;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .status .failure {
    color: #FF4136;
    -webkit-animation: fadeOut 6s forwards;
            animation: fadeOut 6s forwards;
    background-color: #e6e6e6;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes bigSqrShrink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@keyframes bigSqrShrink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@-webkit-keyframes drop2 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  25% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes drop2 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  25% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes drop3 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  50% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes drop3 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  50% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes drop4 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  75% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes drop4 {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  75% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0); } }

.shipment-tab-menu {
  margin-top: 40px; }
  .shipment-tab-menu .tabs .tab-btn {
    width: 20%; }
  .shipment-tab-menu .tab-body {
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.pallet-add .dimensions {
  margin-top: 10px;
  display: inline-block; }
  .pallet-add .dimensions .pallet-input {
    width: 30px;
    margin-right: 10px; }

.pallet-add .save-btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px; }

.pagination .pages {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .pagination .pages .page {
    background-color: #e6e6e6;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    justify-self: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .pagination .pages .page:hover {
    background-color: #ffb471; }
  .pagination .pages .active {
    background-color: #ffb471; }

.pagination .enter-page {
  display: grid;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px; }
  .pagination .enter-page input {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border-width: 1px;
    text-align: center;
    outline: 0;
    font-size: 16px; }

.filter-dropdown-container {
  position: relative; }
  .filter-dropdown-container .filter-dropdown {
    width: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #f8f8f8;
    border-width: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    z-index: 10;
    position: absolute;
    max-height: 280px; }
    .filter-dropdown-container .filter-dropdown .filter-head {
      display: grid;
      grid-template-columns: 1fr 1fr;
      cursor: pointer; }
      .filter-dropdown-container .filter-dropdown .filter-head .link {
        justify-self: right; }
    .filter-dropdown-container .filter-dropdown .filter-static-head .filter-head {
      cursor: pointer;
      padding: 5px;
      padding-left: 10px;
      display: grid; }
    .filter-dropdown-container .filter-dropdown .filter-input, .filter-dropdown-container .filter-dropdown select {
      width: calc(100% - 20px);
      margin: 10px 10px 10px 10px;
      padding: 5px; }
    .filter-dropdown-container .filter-dropdown .options {
      max-height: 200px;
      overflow-y: scroll; }
      .filter-dropdown-container .filter-dropdown .options .filter-dropdown-option {
        padding: 5px;
        background-color: #e6e6e6;
        margin: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .filter-dropdown-container .filter-dropdown .filter-dropdown-option:hover:not(.filter-head) {
      background-color: #a0e3fd;
      cursor: pointer; }
    .filter-dropdown-container .filter-dropdown .link {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      justify-self: right;
      background-color: #ffb471;
      width: 19px;
      height: 19px;
      text-align: center;
      border-radius: 19px; }
    .filter-dropdown-container .filter-dropdown .link:hover {
      background-color: #ffa758; }
  .filter-dropdown-container .filter-dropdown.closed {
    padding: 5px 10px 5px 10px; }

.part-info {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 2px;
  max-width: 576px; }
  .part-info label.header {
    display: block;
    font-size: 28px;
    margin-bottom: 10px; }
  .part-info .values {
    display: grid; }
  .part-info .description,
  .part-info .extended-description,
  .part-info .cost,
  .part-info .weight-each,
  .part-info .material {
    display: grid;
    grid-template-columns: 3fr 5fr;
    padding: 10px;
    border: solid;
    border-width: 0px 0px 1px 0px;
    border-color: gray; }
    .part-info .description .value,
    .part-info .extended-description .value,
    .part-info .cost .value,
    .part-info .weight-each .value,
    .part-info .material .value {
      justify-self: right; }
    .part-info .description input,
    .part-info .extended-description input,
    .part-info .cost input,
    .part-info .weight-each input,
    .part-info .material input {
      display: inline-block;
      justify-self: right;
      text-align: right; }
  .part-info .extended-description .value {
    text-align: right; }
  .part-info .weight-each #weight-each {
    width: 60px; }
  .part-info .material {
    margin-bottom: 20px; }
    .part-info .material #material {
      width: 100%; }
  .part-info .save-btn {
    justify-self: right; }

.part-list-labels {
  justify-self: left !important;
  display: fixed;
  left: 0; }

.label {
  width: 4in;
  height: 1.4in;
  margin: 0; }

.label-top-row {
  padding-top: .1in; }

.label-logo {
  width: 2in;
  float: left;
  padding-left: .1in; }

.label-barcode-wrap {
  float: right;
  padding-right: .2in;
  line-height: 80%; }

.label-barcode {
  width: 1.6in;
  height: .5in; }

.label-clear {
  clear: both; }

.label-description {
  margin-top: -10px;
  font-size: 1.15em;
  font-family: monospace; }

.label-lower {
  padding-left: .1in;
  padding-right: .1in; }

.label-address-wrap {
  float: left; }

.label-address {
  line-height: 85%;
  margin-bottom: 0;
  margin-top: 0px;
  font-size: .75em;
  font-family: monospace; }

.label-info-table {
  line-height: 50%;
  font-family: monospace;
  margin-top: -10px; }
  .label-info-table tr {
    margin: 0;
    padding: 0; }

.badge {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #83ca8c;
  text-align: center;
  cursor: default; }
  .badge.missing-translations {
    background-color: #f55c5c; }
  .badge.missing-translations:hover {
    background-color: #ffaf67; }

.sort-labels {
  display: grid;
  padding: 5px;
  background-color: #f8f8f8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .sort-labels .sort-label:not(.selected) .sort-symbol {
    visibility: hidden !important; }
  .sort-labels .sort-label:not(.cannot-select), .sort-labels .sort-label:not(.cannot-select) > * {
    cursor: pointer; }
  .sort-labels .sort-label label:not(.cannot-select):hover {
    cursor: pointer;
    background-color: #e6e6e6;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .sort-labels .cannot-select, .sort-labels .cannot-select > * {
    cursor: default !important;
    background-color: inherit !important;
    box-shadow: none !important; }

.phantom-frame {
  display: grid; }
  .phantom-frame .phantom-wpn-input {
    padding: 10px;
    min-width: 150px;
    margin-bottom: 10px; }
  .phantom-frame .sort-labels {
    margin-bottom: 10px; }
  .phantom-frame .save-btn {
    justify-self: right;
    width: 200px; }

.deleted-part-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin: 0 0 8px 0; }
  .deleted-part-row .part-number,
  .deleted-part-row .revision,
  .deleted-part-row .description,
  .deleted-part-row .deleted-qty {
    display: inline-block;
    margin: 0px 20px 0px 20px; }
  .deleted-part-row .info {
    display: grid;
    grid-template-columns: 2fr 1fr 4fr; }
  .deleted-part-row .info > * {
    cursor: default; }
  .deleted-part-row .part-number {
    margin-right: 0px; }
  .deleted-part-row .description {
    font-size: 11px; }
  .deleted-part-row .part-number, .deleted-part-row .revision, .deleted-part-row .description {
    height: 21px;
    line-height: 21px; }
  .deleted-part-row .part-number:hover,
  .deleted-part-row .revision:hover,
  .deleted-part-row .description:hover {
    text-decoration: underline;
    cursor: pointer; }
  .deleted-part-row .kitted-qty, .deleted-part-row .remaining-qty {
    cursor: default; }
  .deleted-part-row .update-qty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center; }
    .deleted-part-row .update-qty .pack-qty {
      width: 40px;
      justify-self: center; }
    .deleted-part-row .update-qty .qty-per-bag {
      width: 40px;
      justify-self: center; }
  .deleted-part-row .kitted-qty {
    justify-self: left; }
  @media (max-width: 740px) {
    .deleted-part-row .part-number, .deleted-part-row .description {
      font-size: 10px; } }

.kitted-part-row:hover {
  background-color: #a0e3fd; }

/* Composite components */
.part-show {
  display: block;
  margin: 0 auto;
  max-width: 1000px;
  margin-top: 20px; }
  .part-show .part-search {
    margin-bottom: 20px;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 5px;
    padding-bottom: 10px;
    width: 400px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .part-show .part-search .part-title {
      font-size: 28px;
      display: block;
      margin-bottom: 5px; }
    .part-show .part-search .part-number {
      width: 200px;
      margin-right: 20px; }
    .part-show .part-search .dropdown-menu select {
      width: 100px; }
  .part-show .translations-codes-container {
    display: grid;
    margin-bottom: 20px;
    grid-template-areas: "translations translations codes"; }
    .part-show .translations-codes-container .translations-title {
      grid-area: tlabel;
      font-size: 28px;
      display: block; }
    .part-show .translations-codes-container .translations, .part-show .translations-codes-container .codes {
      display: inline-block; }
    .part-show .translations-codes-container .translations {
      grid-area: translations;
      margin-right: 20px;
      padding: 15px;
      background-color: #f8f8f8;
      border-radius: 3px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
      .part-show .translations-codes-container .translations .translation-row {
        margin-bottom: 10px; }
      .part-show .translations-codes-container .translations .save-btn {
        display: block;
        margin-right: 15px;
        margin-left: auto; }
    .part-show .translations-codes-container .codes-container {
      grid-area: codes;
      vertical-align: top;
      height: 300px;
      width: 275px;
      background-color: #f8f8f8;
      border-radius: 3px;
      padding: 15px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
      .part-show .translations-codes-container .codes-container .save-btn {
        float: right; }
    @media (max-width: 900px) {
      .part-show .translations-codes-container {
        grid-template-areas: "tlabel       ." "translations translations" "codes        ."; }
        .part-show .translations-codes-container .part-search {
          width: -webkit-max-content;
          width: max-content; }
        .part-show .translations-codes-container .codes-container {
          justify-self: left;
          margin-top: 20px;
          width: -webkit-max-content;
          width: max-content; } }

.ship-list {
  height: 100%;
  display: grid; }
  .ship-list a {
    text-decoration: none;
    color: #1f1f1f; }
  .ship-list .shipment-title {
    display: block;
    margin-bottom: 10px;
    font-size: 32px; }
  .ship-list .filter-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 20px; }
    .ship-list .filter-row .filter-ship-list {
      height: 42px;
      width: 300px; }
    .ship-list .filter-row .form-submission-btn, .ship-list .filter-row a {
      justify-self: right; }
  .ship-list .partlist-labels {
    display: grid;
    grid-auto-rows: 20px;
    grid-template-columns: 1fr 3fr 2fr;
    margin-bottom: 5px;
    background-color: #f8f8f8;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .ship-list .partlist-labels #shipment {
      text-align: right; }
  .ship-list .ship-list-index {
    justify-self: center;
    max-width: 800px; }
  .ship-list .partlists {
    margin-bottom: 20px;
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .ship-list .partlists .partlist-row {
      margin-bottom: 10px; }

.ship-list-create {
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 15px;
  height: 220px;
  justify-self: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .ship-list-create label {
    display: block; }
  .ship-list-create .create-ship-list-title {
    font-size: 28px;
    margin-bottom: 20px; }
  .ship-list-create .orders-title {
    color: #243241;
    font-size: 12px;
    margin-bottom: 5px; }
  .ship-list-create .parts-input {
    display: block;
    width: 400px;
    height: 42px;
    margin-bottom: 20px; }
  .ship-list-create .form-submission-btn {
    float: right; }

.shipment-title {
  margin-top: 0px; }

.ship-list-show {
  max-width: 900px;
  justify-self: center; }
  .ship-list-show .shipment {
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .ship-list-show .shipment .partlist-info {
      display: grid;
      grid-template-columns: 5fr 1fr 3fr; }
    .ship-list-show .shipment .button-panel {
      display: grid;
      grid-template-columns: repeat(4, 1fr); }
    .ship-list-show .shipment .title {
      font-size: 28px; }
    .ship-list-show .shipment .dropdown-label {
      text-align: center;
      vertical-align: middle;
      line-height: 34px;
      color: #243241; }
    .ship-list-show .shipment .dropdown-menu.shipment-select {
      justify-self: right;
      -webkit-align-self: center;
              align-self: center; }
      .ship-list-show .shipment .dropdown-menu.shipment-select select {
        height: 28px;
        min-width: 150px;
        justify-self: right; }
  .ship-list-show .job-orders {
    margin-bottom: 20px;
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .ship-list-show .job-orders .job-orders-label,
    .ship-list-show .job-orders input {
      display: block;
      font-size: 22px;
      margin-bottom: 10px; }
    .ship-list-show .job-orders input {
      width: 100%;
      margin-bottom: 10px; }
    .ship-list-show .job-orders .form-submission-btn {
      display: block;
      margin-left: auto;
      margin-right: 0px; }
  .ship-list-show .required-parts-container {
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; }
    .ship-list-show .required-parts-container .sort-labels {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr 6fr 2fr 2fr 1fr; }
    .ship-list-show .required-parts-container div.sort-labels > div:nth-child(1) {
      grid-column-start: 2; }
    .ship-list-show .required-parts-container .save-btn {
      display: block;
      margin-left: auto;
      margin-right: 0px; }
  .ship-list-show .required-parts-container > * {
    margin-bottom: 10px; }
  .ship-list-show .phantom-parts-container {
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; }
    .ship-list-show .phantom-parts-container .sort-labels {
      display: grid;
      grid-template-columns: 2fr 1fr 4fr 1fr;
      padding: 5px 10px; }
  .ship-list-show .deleted-parts-container {
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; }
    .ship-list-show .deleted-parts-container .sort-labels {
      display: grid;
      grid-template-columns: 2fr 1fr 4fr 1fr 1fr;
      margin-bottom: 10px; }

.shipment-base {
  display: grid;
  -webkit-justify-content: center;
          justify-content: center; }

.shipment-index {
  max-width: 800px;
  width: 97vw; }
  .shipment-index .status {
    display: grid;
    -webkit-justify-content: center;
            justify-content: center; }
  .shipment-index .shipment-label {
    margin-top: 20px;
    font-size: 28px;
    display: block;
    margin-bottom: 20px; }
  .shipment-index .labels {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr; }
    .shipment-index .labels #id, .shipment-index .labels #ship-by {
      padding: 0px 10px; }
    .shipment-index .labels #ship-by {
      text-align: right; }
  .shipment-index .shipment-filter-input {
    width: 350px;
    height: 40px; }
  .shipment-index .form-submission-btn {
    float: right; }
  .shipment-index .shipment-tab-menu {
    display: grid;
    margin: 20px auto; }

.shipment-show {
  margin-top: 20px;
  max-width: 1200px; }
  .shipment-show .kitted-parts-container,
  .shipment-show .packed-parts-container,
  .shipment-show .queued-parts-container,
  .shipment-show .shipment-title {
    background-color: #f8f8f8;
    border-radius: 2px;
    margin-top: 20px;
    padding: 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .shipment-show .shipment-title {
    margin-top: 0px;
    padding: 20px; }
    .shipment-show .shipment-title .last-modified,
    .shipment-show .shipment-title .ship-by-date {
      font-size: 14px; }
  .shipment-show .shipment-summary {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    justify-self: center; }
    .shipment-show .shipment-summary .shipment-title {
      font-size: 28px;
      justify-self: center; }
    .shipment-show .shipment-summary .partlists {
      font-size: 12px;
      margin: 15px 0px; }
      .shipment-show .shipment-summary .partlists .partlist-row {
        height: 22px;
        line-height: 22px;
        margin-bottom: 10px; }
    .shipment-show .shipment-summary .button-panel {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 10px; }
      .shipment-show .shipment-summary .button-panel .create-btn {
        width: 200px; }
    .shipment-show .shipment-summary label {
      display: block;
      margin-bottom: 10px; }
    .shipment-show .shipment-summary .form-submission-btn {
      float: right; }
    .shipment-show .shipment-summary .edit-btn {
      width: 210px;
      margin-right: 20px; }
    .shipment-show .shipment-summary .create-btn {
      margin-right: 20px; }
  .shipment-show .shipments-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    -webkit-align-items: end;
            align-items: end; }
    .shipment-show .shipments-row .filter-shipment-parts {
      height: 30px; }
    .shipment-show .shipments-row .ship-lists-dropdown {
      display: grid;
      grid-auto-flow: row;
      margin-left: 20px; }
      .shipment-show .shipments-row .ship-lists-dropdown .dropdown-menu {
        width: 70%; }
      .shipment-show .shipments-row .ship-lists-dropdown .dropdown-menu > select {
        width: 100%; }
  .shipment-show .kitted-parts-container {
    padding: 20px; }
    .shipment-show .kitted-parts-container .sort-labels {
      display: grid;
      grid-template-columns: 2fr 1fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
      margin-bottom: 10px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(3) {
        margin-left: 20px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(4) {
        margin-left: 10px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(5) {
        margin-left: 10px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(6) {
        margin-left: 30px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(7) {
        margin-left: 40px; }
      .shipment-show .kitted-parts-container .sort-labels div:nth-child(8) {
        margin-left: 30px; }
    .shipment-show .kitted-parts-container label.kitted-parts {
      font-size: 32px; }
    .shipment-show .kitted-parts-container .kitted-part-row {
      margin-bottom: 10px; }
    .shipment-show .kitted-parts-container .update-kitted-parts {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 1fr;
      margin-top: 20px;
      -webkit-align-items: top;
              align-items: top;
      justify-items: right;
      -webkit-align-items: center;
              align-items: center; }
      .shipment-show .kitted-parts-container .update-kitted-parts label,
      .shipment-show .kitted-parts-container .update-kitted-parts .dropdown-menu {
        justify-self: right;
        margin-right: 10px; }
      .shipment-show .kitted-parts-container .update-kitted-parts label {
        color: #243241; }
      .shipment-show .kitted-parts-container .update-kitted-parts .dropdown-menu {
        width: 150px; }
      .shipment-show .kitted-parts-container .update-kitted-parts .dropdown-menu select {
        width: 100%; }
      .shipment-show .kitted-parts-container .update-kitted-parts .pallet {
        grid-column: 2;
        justify-items: right; }
      .shipment-show .kitted-parts-container .update-kitted-parts .form-submission-btn {
        justify-self: right; }
  .shipment-show .packed-parts-container .packed-parts {
    font-size: 32px; }
  .shipment-show .packed-parts-container .packed-part-row {
    margin-bottom: 10px; }
  .shipment-show .packed-parts-container .sort-labels {
    display: grid;
    grid-template-columns: 2fr 1fr 3fr 2fr 2fr 2fr 2fr;
    margin-bottom: 10px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(1) {
      margin-left: 40px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(2) {
      margin-left: 40px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(3) {
      margin-left: 30px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(4) {
      margin-left: 50px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(5) {
      margin-left: 20px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(6) {
      margin-left: 30px; }
    .shipment-show .packed-parts-container .sort-labels div:nth-child(7) {
      margin-left: 70px; }
  .shipment-show .packed-parts-container .form-submission-btn {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 20px; }
  .shipment-show .queued-parts-container .sort-labels {
    display: grid;
    grid-template-columns: 2fr 1fr 5fr 3fr 2fr 2fr 2fr 2fr;
    margin-bottom: 10px; }
    .shipment-show .queued-parts-container .sort-labels div:nth-child(2) {
      text-align: right; }
    .shipment-show .queued-parts-container .sort-labels div:nth-child(3) {
      margin-left: 30px; }
    .shipment-show .queued-parts-container .sort-labels div:nth-child(6) {
      margin-left: 10px; }
    .shipment-show .queued-parts-container .sort-labels div:nth-child(7) {
      margin-left: 30px; }
  .shipment-show .queued-parts-container .queued-parts {
    font-size: 32px; }
  .shipment-show .queued-parts-container .queued-part-row {
    margin-bottom: 10px; }
  .shipment-show .queued-parts-container .queued-labels {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    margin-bottom: 10px; }
  .shipment-show .queued-parts-container label {
    text-align: center; }
  .shipment-show .queued-parts-container .parts-required {
    grid-column: 2; }
  .shipment-show .queued-parts-container .form-submission-btn {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 20px; }
  .shipment-show .rollover-warning {
    background-color: #ffc18b;
    margin: 0.25rem;
    padding: 2rem; }

.shipment-create {
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .shipment-create label {
    display: block; }
  .shipment-create .create-shipment-title {
    font-size: 28px;
    margin-bottom: 20px; }
  .shipment-create .ship-date-title {
    color: #e6e6e6;
    font-size: 12px;
    margin-bottom: 5px; }
  .shipment-create .date-input {
    display: block;
    width: 400px;
    height: 42px;
    margin-bottom: 20px; }
  .shipment-create .form-submission-btn {
    float: right; }

.navbar {
  height: 50px;
  margin-bottom: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(197, 112, 112, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .navbar .logo {
    height: 5px;
    font-size: 28px;
    line-height: 50px;
    vertical-align: middle;
    margin-left: 20%; }
    .navbar .logo mark {
      color: #ffb471;
      background-color: inherit; }
  @media (max-width: 620px) {
    .navbar {
      grid-template-columns: 1fr; }
      .navbar .logo {
        display: none; } }
  .navbar .navlinks {
    margin-right: 20%; }
    .navbar .navlinks .navlink {
      display: grid;
      float: right;
      font-size: 18px;
      margin-left: 20px;
      height: 50px;
      line-height: 50px;
      justify-self: right; }
  .navbar .navlink:hover, .navbar .navlink.active {
    border-bottom: solid 2px #ffb471; }

.landing-container {
  height: calc(100% - 80px);
  display: grid;
  -webkit-justify-content: center;
          justify-content: center;
  grid-template-rows: 1fr 6fr; }
  .landing-container .intro {
    max-width: 800px;
    font-size: 24px;
    vertical-align: middle; }
  .landing-container .landing {
    display: grid;
    height: calc(100% - 80px);
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-gap: 20px;
    margin-top: 30px;
    max-width: 800px; }
    .landing-container .landing .landing-card {
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s;
      border-radius: 3px;
      background-color: #f8f8f8;
      height: 100%;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
      .landing-container .landing .landing-card .title {
        background-color: #a0e3fd;
        font-size: 28px;
        text-align: center;
        border-radius: 3px 3px 0px 0px;
        padding: 10px 0px; }
      .landing-container .landing .landing-card .description {
        padding: 20px; }
        .landing-container .landing .landing-card .description li {
          margin-bottom: 15px; }
    .landing-container .landing .landing-card:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }

.login-container {
  display: grid;
  -webkit-justify-content: center;
          justify-content: center; }
  .login-container .login-form {
    width: 300px;
    display: grid;
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .login-container .login-form input {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 18px; }
    .login-container .login-form h1 {
      margin-top: 0px; }
    .login-container .login-form .reveal-password-form {
      display: grid;
      grid-template-columns: auto 1fr; }
      .login-container .login-form .reveal-password-form input[type='checkbox'] {
        width: 20px;
        height: 20px;
        margin: 0 0 0 20px; }
    .login-container .login-form .save-btn {
      justify-self: end;
      margin-top: 10px;
      width: 100%; }

.partlist-modal {
  position: fixed;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  display: grid; }
  .partlist-modal h1 {
    color: #f8f8f8;
    z-index: 2;
    justify-self: center;
    margin: 15px; }
  .partlist-modal .overlay {
    position: fixed;
    -webkit-transform: translateY(-90px);
            transform: translateY(-90px);
    background: #1f1f1f;
    opacity: 0.9;
    width: 100%;
    height: 100vmax; }
  .partlist-modal .close-container {
    width: 100%;
    justify-self: center;
    z-index: 2;
    max-width: 900px;
    margin: 0 auto; }
    .partlist-modal .close-container .delete-btn {
      width: 40px;
      float: right;
      z-index: 2;
      height: 40px;
      margin-top: 20px; }
  .partlist-modal .loading-msg {
    text-align: center;
    color: #f8f8f8;
    z-index: 2; }
  .partlist-modal .partlists {
    max-width: 900px;
    z-index: 2;
    justify-self: center;
    overflow: scroll;
    padding-right: 20px;
    height: 70vh;
    grid-auto-rows: 50px 50px 5fr;
    overflow-x: hidden; }
    .partlist-modal .partlists .row {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr;
      grid-auto-rows: 60px;
      grid-column-gap: 20px;
      -webkit-align-items: center;
              align-items: center; }
      .partlist-modal .partlists .row a {
        pointer-events: none; }
      .partlist-modal .partlists .row .partlist-row {
        pointer-events: none; }

* {
  box-sizing: border-box; }

html,
body,
.app {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue", "Roboto", san-serif;
  color: #1f1f1f;
  background-color: #fefefe;
  margin: 0; }

#root {
  height: 100vh; }

.about-container{display:grid;grid-gap:16px;-webkit-justify-content:center;justify-content:center}

.customs-codes-badge {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #83ca8c;
  text-align: center;
  cursor: default; }
  .customs-codes-badge.missing-codes {
    background-color: #f55c5c; }
  .customs-codes-badge.missing-codes:hover {
    background-color: #ffaf67; }
  .customs-codes-badge.missing-partial-codes {
    background-color: #ffc18b; }
  .customs-codes-badge.missing-partial-codes:hover {
    background-color: #ffaf67; }

.phantom-part {
  display: grid;
  grid-template-columns: 2fr 1fr 4fr 1fr;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #e6e6e6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  .phantom-part .req-qty {
    justify-self: right; }

#audit-log #logs {
  overflow-y: scroll;
  max-height: 300px; }

#picklist #pick-list-columns {
  display: grid;
  grid-template-columns: 80px 28px 300px 40px 40px 50px;
  grid-gap: 10px; }

#picklist {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto; }
  #picklist a:hover {
    text-decoration: underline; }
  #picklist #pick-list-parts > div:nth-child(odd) {
    background-color: #f5f5f5; }
  #picklist #pick-list-title {
    margin-bottom: 10px;
    display: inline-block; }
  #picklist #save-pick-list {
    float: right; }
  #picklist .numeric {
    text-align: right; }

.pick-list-row {
  display: grid;
  grid-template-columns: 80px 28px 300px 40px 40px 50px;
  grid-gap: 10px; }

.pick-list-row {
  margin-bottom: 10px; }
  .pick-list-row .totalRequiredQuantity,
  .pick-list-row .totalKittedQuantity {
    text-align: right; }
  .pick-list-row .toKitQuantity {
    text-align: right;
    width: 100%; }

.part-creation {
  width: 300px;
  margin: 0 auto;
  display: grid;
  grid-gap: 8px; }
  .part-creation input,
  .part-creation button,
  .part-creation select {
    padding: 4px; }
  .part-creation .part-identifier {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto; }
    .part-creation .part-identifier .revision {
      max-width: 70px;
      justify-self: right; }
  .part-creation .cost {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto; }

